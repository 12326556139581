.main-menu .row {
    margin-left: 0;
    margin-right: 0;
}

.main-menu .col-md-4 {
    padding-left: 0;
    padding-right: 0;
}

.main-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

.main-menu ul li {
    margin: 0;
}
